import CheckAvailability from "../components/Rebrand/CheckAvailability/CheckAvailability";
import React, { useEffect, useContext } from "react";
import Seo from "../components/Seo";
import ChooseYou from "../components/Rebrand/ChooseYou/ChooseYou";
import TrustBoxCarousel from "../components/TrustBoxCarousel";
import DynamicPricingSection from "../sections/PricingSection/DynamicPricingSection";
import YouMesh from "../components/Rebrand/YouMesh/YouMesh";
import YouPhone from "../components/Rebrand/YouPhone/YouPhone";
import Contact from "../components/Rebrand/Contact/Contact";
import { getProperty } from "./../utils/helperFn";
import { graphql } from "gatsby";
import API from './../api/api';
import jwt from 'jsonwebtoken';
import GlobalContext from './../context/GlobalContext';
import { navigate } from 'gatsby';
import HeroLandingPriceFix from "../components/Rebrand/Hero/HeroLandingPriceFix";
import HeroLandingCustomOffer from "../components/Rebrand/Hero/HeroLandingCustomOffer";
import HeroBlackFriday from "../components/Rebrand/Hero/HeroBlackFriday";

const LandingPage = (props) => {
    const data = props.data ? getProperty(props, 'data') : {}
    const campaignData = getProperty(data, 'strapiLandingPages');
    const gContext = useContext(GlobalContext);

    const preloadAddress = (pid) => {
        API.get(`/CrmModule/v2.0/premises/${pid}`)
            .then((response) => {
                if (response.data.data._sessionId) {
                    const token = response.data.data._sessionId;
                    let decoded = null;
                    try {
                        decoded = jwt.verify(token, process.env.GATSBY_JWT_TOKEN);
                    } catch (err) {
                        decoded = {
                            IsBDUK: 'false',
                            NetomniaSalesStatus: null,
                            SalesStatus: null,
                            BuildStatus: 0,
                            Providers: null
                        };
                    }

                    response.data.data.properties.NetomniaSalesStatus = decoded.NetomniaSalesStatus;
                    response.data.data.properties.SalesStatus = decoded.SalesStatus;
                    response.data.data.properties.IsBDUK = decoded.IsBDUK;
                    response.data.data.properties.Providers = decoded.Providers;
                    response.data.data.properties.BuildStatus = decoded.BuildStatus;
                }

                const skipBooking = response?.data?.data?.properties?.Providers?.includes('CITYFIBRE') ?? false;

                if (typeof document !== "undefined") {
                    if (response.data.data.properties.SalesStatus === 'ORDER') {
                        document.cookie = "user_rfs=true";
                    }
                }

                gContext.goSetRegistrationData({
                    ...gContext.goGetRegistrationData(),
                    skipBooking,
                    searchPremiseResults: response.data.data,
                });
            })
            .catch((error) => {
                gContext.setAPIErrorMsg({
                    title: 'Registration Error',
                    message: error.response?.data?.message
                        ? error.response?.data?.message
                        : 'There was a problem accessing our servers. Please try again later.',
                });
            });
    };

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const params = new URLSearchParams(props.location.search);
            const aid = params.get('aid');
            const pid = params.get('pid');

            if (aid) {
                sessionStorage.setItem('aid', aid);
            }
            
            // Now, you can use the pid directly with the hook
            if (pid) preloadAddress(pid);
        }
    }, [props.location.search]);


    useEffect(() => {
        gContext.setHeaderVisible(true);
        gContext.setFooterVisible(true);

        if (typeof window !== 'undefined') {
            localStorage.removeItem('registrationData')
            gContext.goResetRegistrationData()
        }
        const sprn = new URLSearchParams(props.location.search).get('sprn')
        const source = new URLSearchParams(props.location.search).get('source')
        if (sprn && source) {
            if (source === "n") {
                if (typeof window !== 'undefined' && window) {
                    sessionStorage.setItem('slug', 'netomnia')
                }
            }
            navigate(`/register-residential`, {
                state: {
                    sprn
                },
            });
        }
    }, [])

    return <>
        <Seo page={campaignData.slug} />
        <HeroLandingCustomOffer />
        <div className="pt-5 pb-5">
            <DynamicPricingSection
                location={campaignData.slug}
                searchParams={props.location.search}
            />
        </div>
        <ChooseYou />
        <TrustBoxCarousel />
        <YouMesh />
        <YouPhone />
        <Contact />
        <CheckAvailability center bg="green-bg" title={'Are we right up your street?'} />
    </>;
};

export const data = graphql`
    query getLandingPage($slug: String) {
        strapiLandingPages(slug: {eq: $slug}) {
            slug
            title
            subtitle
        }
    }
`;

export default LandingPage
