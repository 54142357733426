import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Section, Title, Text, PageTitle, Box } from "../components/Core";
import { graphql, useStaticQuery } from "gatsby";
import Seo from "../components/Seo/Seo";
import { getProperty } from "../utils/helperFn";
import SupportSectionSetup from "../sections/SupportSectionSetup/SupportSectionSetup"

import modem1 from './../assets/image/png/modem-1.png'
import modem2 from './../assets/image/png/modem-2.png'

import router1 from './../assets/pdf/router-1.pdf'
import router2 from './../assets/pdf/router-2.pdf'

const GetStarted = () => {

    const data = useStaticQuery(graphql`
        query getstartedPageQuery {
            strapiSupportSectionSetup {
                section_title
                section_text
                feature1_title
                feature1_text
                feature1_icon
                feature2_title
                feature2_text
                feature2_icon  
          }
          strapiSetupPage {
            title
            text_1
            text_2
          }
        }
    `)

    const supportSectionData = getProperty(data, 'strapiSupportSectionSetup')
    const setupPageData = getProperty(data, 'strapiSetupPage')

    return (
        <>
            <Seo page="setup" />
            <Section className="pb-0">
                <div className="pt-5" />
                <Container>
                    <Row className="justify-content-center text-center">
                        <Col lg="8">
                            <h1 variant="hero">{getProperty(setupPageData, 'title')}</h1>
                            <br></br>
                        </Col>
                    </Row>
                    <Row className="justify-content-center text-center">
                        <Col lg="12">
                            <Text>
                                {getProperty(setupPageData, 'text_1')}
                                <br></br>
                                <br></br>
                                {getProperty(setupPageData, 'text_2')}
                                <br></br>
                                <br></br>
                            </Text>
                        </Col>
                    </Row>
                    <Row className="justify-content-center text-center router-images">
                        <Col md="6" className="full-centered">
                            <a href={router1} target="_blank">
                                <img src={modem1}></img>
                            </a>
                        </Col>
                        <Col md="6" className="full-centered">
                            <a href={router2} target="_blank">
                                <img src={modem2}></img>
                            </a>
                        </Col>
                    </Row>
                </Container>
            </Section>
            {/* <SupportSectionSetup pageData={supportSectionData} /> */}
        </>
    );
};
export default GetStarted;
