import { Container, Row, Col } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import { getPrice, RetrieveFilteredProductsFromOffer } from "../../utils/OdinPricingHelpers";
import Loader from "../../assets/image/gif/loader.gif"
import "./index.scss"
import "./ribbon.scss"
import GlobalContext from "../../context/GlobalContext";
import { navigate } from "gatsby";
import API from "../../api/api";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import styled from "styled-components";
import price27 from './../../assets/image/rebrand/svg/27-99.svg';
import price29 from './../../assets/image/rebrand/svg/29-99.svg';

import blackTick from './../../assets/image/svg/tick.svg';
import { isMobile } from "react-device-detect";

const PriceImage = styled.img`
  position: absolute;
  left: 0;
  right: 0;
  background: #fff;
  width: 183px;
  margin: auto;
  top: 7px;
  @media (max-width: 1200px) {
    width: 167px;
  }
  @media (max-width: 992px) {
    width: 167px;
  }
  @media (max-width: 767px) {
    width: 137px;
  }
  @media (max-width: 576px) {
    width: 167px;
  }
`;

const ProductDescriptionSmall = styled.small`
  font-size: 9px;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'DM Sans';
  font-weight: 500;
  img {
    margin-right: 4px;
    width: 12px;
  }
`;

export const RenderPricingBoxes = (props) => {
  const gContext = useContext(GlobalContext);
  const [premise, setPremise] = useState(null);
  const [showDetails, setShowDetails] = useState(null);

  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
    1200: { items: 5 },
  };

  useEffect(() => {
    if (gContext.goGetRegistrationData() && gContext.goGetRegistrationData().selectedProduct) {
      const updatedStepsData = { ...gContext.goGetSteps() };
      if (updatedStepsData.hasOwnProperty("2")) {
        updatedStepsData["2"] = {
          ...updatedStepsData["2"],
          stepVisited: true,
        };
        gContext.goSetSteps(updatedStepsData)
      }
    }

    if (props.checkoutLanding && props.sprn) {
      gContext.showFullScreenLoader();
      setShowDetails(null);
      API.get(`/CrmModule/v2.0/premises/${props.sprn}`)
        .then(
          (resp) => {
            setPremise(resp.data.data);
            gContext.hideFullScreenLoader()
          }
        )
        .catch(
          (error) => {
            gContext.hideFullScreenLoader()
            gContext.setAPIErrorMsg({
              title: 'Error',
              message: error.response ? error.response?.data.message : error
            })
            navigate('/')
          }
        )
    }
  }, []);

  const toggleDetails = (index) => {
    if (typeof window !== 'undefined' && window) {
      document.getElementById(`pricing-box-${index}`).classList.toggle("details-visible")
    }
  }


  const renderSpecialProductClass = (product) => {
    return (
      product.DisplayName.indexOf('You 1000') > -1 &&
      product.AvailableAddons.filter((addon) => addon.properties.DisplayName === 'YouMesh')
        .length &&
      product.ContractType === 'ANNUAL_18'
    );
  };

  const renderSelectedProductClass = (product) => {
    return gContext.goGetRegistrationData() &&
      gContext.goGetRegistrationData().selectedProduct &&
      gContext.goGetRegistrationData().selectedProduct.properties &&
      ((gContext.goGetRegistrationData().selectedProduct.properties.DisplayName + '-' + gContext.goGetRegistrationData().selectedProduct?.properties?.ContractType) ===
        (product.DisplayName + '-' + product.ContractType))
  };

  const isCustomPriceImageProduct = (product) => {
    return false 
    /*
    return (
      product.DisplayName.indexOf('You 500') > -1 &&
      product.ContractType === 'ANNUAL_18'
    ) || (
      product.DisplayName.indexOf('You 1000') > -1 &&
      product.ContractType === 'ANNUAL_18'
    )
    */
  }

  const singlePricingBox = (productToRender, product, ind) => (
    <>
      <Col xs={12} key={ind} className="pb-wrapper flex flex-column justify-between mb-3 p-0">
        <Col xs={12}>
          {renderSelectedProductClass(product) && <div className="tag">{(props.slug === 'checkout' || props.slug === 'recontract') ? 'Selected' : 'Big deal'}</div>}
          <div className={`pb-header flex-grow-0 ${renderSelectedProductClass(product) && 'green-bg'}`}>
            {showDetails !== (product.DisplayName + product.ContractType) && <h2>{product.DisplayName}</h2>}
            {showDetails === (product.DisplayName + product.ContractType) && <h4>{product.DisplayName} | £{getPrice(product).toFixed(2)}</h4>}
          </div>
        </Col>
        <Col xs={12}>
          <Row className="pb-speed-wrapper">
            <Col sm={6}>
              <Row>
                <Col sm={12} className="m-auto text-center">{showDetails !== (product.DisplayName + product.ContractType) && <h4>{product.Description} Mbps</h4>}</Col>
                <Col sm={12} className="text-center"><p>Avg. upload speed</p></Col>
              </Row>
            </Col>
            <Col sm={6}>
              <Row>
                <Col sm={12} className="m-auto text-center">{showDetails !== (product.DisplayName + product.ContractType) && <h4>{product.Description} Mbps</h4>}</Col>
                <Col sm={12} className="text-center"><p>Avg. download speed</p></Col>
              </Row>
            </Col>
          </Row>
          {showDetails !== (product.DisplayName + product.ContractType) &&
            <div className="pb-content text-center">
              <ProductDescriptionSmall className="mt-3">
                <img src={blackTick}/>No set-up fees
              </ProductDescriptionSmall>
              {
                gContext?.goGetRegistrationData()?.offer?.properties?.ContractType !== 'MONTHLY' &&
                <ProductDescriptionSmall className="mb-2">
                  <img src={blackTick}/>No in-contract price rises
                </ProductDescriptionSmall>
              }
              <br/>
              <h4 className={`flex-grow-0 position-relative ${isCustomPriceImageProduct(product) ? 'white-color' : ''}`}>
                {/* {renderSpecialProductClass(product) ? '£' + getPrice(product).toFixed(2) : ('£' + getPrice(product).toFixed(2))} */}
                {'£' + getPrice(product).toFixed(2)}
                {isCustomPriceImageProduct(product) && <PriceImage src={product.DisplayName.indexOf('You 500') > -1 ? price27 : price29} alt="27 price" />}
              </h4>
              <span className="smallest-font align-center ml-1">per month</span>
              {(props.slug === 'business' || gContext.goGetRegistrationData()?.searchPremiseResults?.properties?.Classification === 'BUSINESS') && <span className="smallest-font align-center ml-1 pt-2">(ex. VAT)</span>}
            </div>
          }
        </Col>
        {showDetails === (product.DisplayName + product.ContractType) &&
          <Col xs={12} className="slide flex-1 flex flex-column justify-content-around align-content-center">
            <Row>
              <Col lg={6} md={6} sm={4} xs={4} className="value text-right">{product.Description}</Col>
              <Col lg={6} md={6} sm={8} xs={8} className="description">Mbps average upload & download speed</Col>
            </Row>
            <Row>
              <Col lg={6} md={6} sm={4} xs={4} className="value text-right">{product.DescriptionLine1 ? product.DescriptionLine1.split(" ")[0] : 0}</Col>
              <Col lg={6} md={6} sm={8} xs={8} className="description">Month contract<br />Full terms & condition</Col>
            </Row>
            <Row>
              <Col lg={6} md={6} sm={4} xs={4} className="value text-right">0</Col>
              <Col lg={6} md={6} sm={8} xs={8} className="description">No set up fees<br />No in contract price-rises</Col>
            </Row>
          </Col>
        }
        
        {
          (gContext.goGetRegistrationData()?.selectedProduct?.id == productToRender.id && props.slug == 'recontract') ?
          <Row className="pb-footer flex-grow-0 p-3">
            <Col sm={12} className="m-auto">
              <span className="pb-btn green-bg d-block disabled-link">Selected</span>
            </Col>
          </Row>
        :
          <Row className="pb-footer flex-grow-0 p-3">
          <Col sm={12} className="m-auto">
            <span className="pb-btn blue-bg d-block"
              onClick={() => {
                // Move to Checkout Page
                if (gContext.goGetRegistrationData() && gContext.goGetRegistrationData().checkoutStep === 1) {
                  gContext.goSetRegistrationData({
                    ...gContext.goGetRegistrationData(),
                    orderItems: [productToRender],
                    offer: props.rawOffer || undefined,
                    selectedProduct: productToRender,
                    checkoutStep: 2,
                  });
                } else {
                  gContext.goSetRegistrationData({
                    ...gContext.goGetRegistrationData(),
                    orderItems: [productToRender],
                    selectedProduct: productToRender,
                    offer: props.rawOffer || undefined,
                    hearAboutUsText: 'Hear about us text',
                  });

                  if (props.checkoutLanding) {
                    const contactId =
                      (props.lead && props.lead.links) ?
                        (props.lead.links.find((item) => item.entity == 'CrmModule:Contact') &&
                          props.lead.links.find((item) => item.entity == 'CrmModule:Contact')['id']) : null;
                    const addressId =
                      (props.lead && props.lead.links) ?
                        (props.lead.links.find((item) => item.entity == 'CrmModule:Address') &&
                          props.lead.links.find((item) => item.entity == 'CrmModule:Address')['id']) : null;
                    const registrationData = {
                      CustomerType: props?.lead?.properties?.Type,
                      SearchPremiseResults: premise,
                      selectedProduct: product,
                      leadId: props?.lead?.id,
                      contactId,
                      addressId,
                      checkoutLanding: true,
                      email: props?.lead?.properties?.EmailAddress,
                      fullName: props.fullName,
                    };
                    navigate('/checkout?s=2', {
                      state: { 
                        registrationData,
                        location: props?.lead?.properties?.Type.toLowerCase()
                      },
                    });
                  } else {
                    navigate('/check-availability/');
                  }
                }
                const updatedStepsData = { ...gContext.goGetSteps() };
                if (updatedStepsData.hasOwnProperty("2")) {
                  updatedStepsData["2"] = {
                    ...updatedStepsData["2"],
                    stepVisited: true,
                  };
                  gContext.goSetSteps(updatedStepsData)
                }

                gContext.goSetAddonsSelected({
                  'Static IP': null,
                  'YouMesh': null,
                  'YouPhone': null
                })

                gContext.goSetToggleState({
                  'YouPhone Plus': false,
                  'YouPhone Pro': false,
                  'Battery Pack': false,
                  'Transfer landline number': false
                })
              }}
            >Select package</span>
          </Col>
        </Row>
        }
      </Col>
    </>
  );

  if (props.rawOffer) {
    let i = 0;
    return (
      <>
        <AliceCarousel
          mouseTracking
          responsive={responsive}
          disableButtonsControls={true}
          autoPlay={!isMobile}
          autoPlayDirection={'ltr'}
          autoPlayStrategy={'all'}
          autoPlayInterval={4000}
          controlsStrategy="alternate">
          {
            RetrieveFilteredProductsFromOffer({
              rawOffer: props.rawOffer.Product,
              productCategory: props.productCategory,
              contractType: props.contractType
            }).map((product, ind) => {
              product.properties.inc = i++;
              return singlePricingBox(product, product.properties, ind)
            })
          }
        </AliceCarousel>
      </>
    )
  } else {
    return [
      <div className="mt-2 mb-5 p-5 text-center" key={1}>
        <h3 className="pb-5 mb-3">Retrieving products...</h3>
        <img src={Loader} alt="Loader" className="img-fluid mx-auto loader" />
      </div>
    ]
  }
};
