import React, { createRef, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import CheckAvailability from '../CheckAvailability/CheckAvailability';
import desktopAnimation from "../../../assets/animations/desktop-new.json"
import lottie from "lottie-web"
import TrustBoxWidget from '../../TrustBoxWidget/TrustBoxWidget';

const animationContainer = createRef()

const HeroLandingCustomOffer = () => {

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: desktopAnimation
    });
  }, [])

  return (
    <Col className="dark-bg homepage ">
      
      <Row className="justify-content-md-center">
        <Col sm={12} md={5}>
          <div
            id="animation-container"
            ref={animationContainer}
          />
        </Col>
      </Row>

      <Row className="justify-content-md-center mt-0">
        <Col lg={8} md={8} sm={12}>
          <p className="text-center largest-font pt-0">
            2024 prices on pause for 2025.<br/>
            150 Mbps just £23.99/month.
          </p>
        </Col>
        <Col lg={6} md={8} sm={12}>
          <CheckAvailability center />
        </Col>
      </Row>

      <Row className="justify-content-md-center">
        <Col lg={5} md={12} sm={12} className="mb-2">
          <TrustBoxWidget />
        </Col>
      </Row>
      <br></br>
    </Col>
  );
};

export default HeroLandingCustomOffer;
